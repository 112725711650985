@import url('https://fonts.googleapis.com/css2?family=Poller+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: 'Authentic';
  src: url('/src/fonts/Authentic-Signature.eot');
  src: url('/src/fonts/Authentic-Signature.eot?#iefix') format('embedded-opentype'),
    url('/src/fonts/Authentic-Signature.woff2') format('woff2'),
    url('/src/fonts/Authentic-Signature.woff') format('woff'),
    url('/src/fonts/Authentic-Signature.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  line-height: 1.7 !important;
  overflow-x: hidden !important;
}

:root {
  --color-primary: #D01018;
  --color-secondray: #1E1E1E;
  --color-white: #fff;
  --color-black: #1E1E1E;
  --font-primary: "Poller One", serif;
  --font-secondary: "Poppins", sans-serif;
  --font-tertiary: "Maven Pro";
  --font-four: "Authentic";
  --font-fith: "KoHo", sans-serif;
}

a {
  text-decoration: none !important;
}

/************* Start custom_btn Button *************/
button.custom_btn {
  border: none;
  cursor: pointer;
  margin: 0px 8px 0px 0px;
  background: var(--color-white);
  border-radius: 5px !important;
}

.custom_btn svg {
  color: var(--color-primary);
}

.custom_btn a {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 12px 45px;
  color: var(--color-black);
}

.custom_btn :hover svg {
  color: var(--color-secondray);
  transition: 0.2s 0.1s;
}

.custom_btn a:hover {
  color: var(--color-secondray);
  transition: 0.2s 0.1s;
}

button.custom_btn_2 {
  padding: 18px 45px !important;
  background: #053b6e !important;
  border: 0px !important;
}

button.custom_btn_3 {
  padding: 18px 45px !important;
  background: #053b6e !important;
  border: 0px !important;
}

button.custom_btn_3 a {
  color: var(--color-white);
}

button.custom_btn_white {
  padding: 18px 45px !important;
  background: var(--color-white) !important;
  border: 0px !important;
}

button.custom_btn_white a {
  color: var(--color-black);
}

button.custom_btn2 {
  font-size: 12px;
  padding: 12px 30px;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--color-white);
}


button.custom_btn2:hover {
  color: var(--color-black);
  border: 1px solid var(--color-primary);
  background: transparent;
}

/************* End custom_btn Button *************/
/************* Start Components Section Head Title *************/
.section_head h1 {
  font-size: 45px;
  font-weight: 700;
  text-transform: uppercase;
}

.section_head {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section_head p {
  width: 50%;
  font-size: 14px;
}

/************* End Components Section Head Title *************/
/************ Start Header Style ***********/
.header-container {
  gap: 30px;
  border-radius: 60px;
  padding: 5px 20px !important;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid hsla(358, 86%, 44%, 0.12);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

a.navbar-logo.navbar-brand img {
  width: 60px;
}

.main_navbar.navbar-nav {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}

.main_navbar2 {
  justify-content: space-between;
  gap: 15px;
}

.main-nav {
  justify-content: space-between;
}

.main_header {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  z-index: 999;
}

a.nav_link.sign-up-btn.active {
  border: 1px solid #aa0f0f;
  border-radius: 5px;
  padding: 8px 20px;
  margin-left: 25px;
}

.main_navbar a.nav-link {
  color: var(--color-white);
}

.main_navbar a.nav_link {
  color: var(--color-secondray);
  font-size: 14px;
  font-family: "Maven Pro", sans-serif;
  font-weight: 500;
  position: relative;
  display: inline-block;
}

/* .main_navbar a.nav-link:hover {
  color: var(--color-primary);
} */

.main_navbar a.nav_link:hover {
  color: var(--color-primary);
}

.main_navbar a.nav_link:hover:before {
  color: var(--color-white);
  width: 100%;
}

.main_navbar a.nav_link::before {
  position: absolute;
  content: "";
  background: var(--color-primary);
  width: 0%;
  height: 1px;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

.header-icon svg {
  color: var(--color-black);
  font-size: 18px;
  padding: 5px;
  background: var(--color-white);
  width: 33px;
  height: 33px;
  border-radius: 50px;
  border: 1px solid var(--color-primary);
}

/************ End Header Style ***********/
/************ Start HeroSlider ***********/
.hero_img {
  position: relative;
}

.hero_img .hero_img0 {
  width: 100%;
}

.hero-slider .slick-prev,
.slick-next {
  top: 700px !important;
}

.hero-slider button.slick-arrow.slick-prev {
  left: 1200px !important;
  z-index: 9999;
}

.hero-slider button.slick-arrow.slick-next {
  right: 300px !important;
  z-index: 9999;
}

.hero-slider .slick-prev:before,
.slick-next:before {
  padding: 14px 12px !important;
  border-radius: 50px;
}

.hero-slider .slick-next:hover:before,
.slick-next:focus:before {
  background: #ffffff !important;
  content: url('./images/right-arrow1.png') !important;
}

.hero-slider .slick-prev:hover:before,
.slick-prev:focus:before {
  background: #ffffff !important;
  content: url('./images/left-arrow1.png') !important;
}

.slick-prev:before {
  content: url('./images/left-arrow.png') !important;
}

.slick-next:before {
  content: url('./images/right-arrow.png') !important;
}

/************* End HeroSlider *************/
/************* Start marquee css *************/
.asdasd {
  position: relative;
  margin: 0px 0px -89px 0px;
}

.marquee-white {
  position: absolute;
  top: 15px;
  width: 100%;
  height: 40px;
  transform: rotate(3deg) !important;
  background: var(--color-white);
  border: 1px solid #D01018;
}

.marquee-black {
  top: -20px;
  position: absolute;
  width: 100%;
  height: 40px;
  transform: rotate(-3deg) !important;
  background: var(--color-black);
}

.marquee-content {
  display: flex;
  gap: 35px;
  margin-left: 35px;
}

.marquee-content p {
  font-family: var(--font-primary);
  margin: 0px;
}

.marquee-black .marquee-content p {
  color: var(--color-white);
}

.marquee-content span {
  color: var(--color-primary);
}

/************* End marquee css *************/
/************* Start OUR COMPAY CSS *************/
.company-bg {
  background-image: url(./images/our-company.png);
  width: 100%;
  height: 980px;
  background-repeat: no-repeat;
  background-size: cover;
}

.company-main {
  padding-top: 200px;
}

img.company-img {
  width: 100%;
  object-fit: cover;
}

h4.company-right-about {
  font-family: Authentic;
  font-size: 40px;
  color: var(--color-primary);
  margin: 0px;
}

h1.company-right-our {
  font-family: var(--font-primary);
  font-size: 50px;
  display: inline-grid;
  line-height: 40px;
  text-transform: uppercase;
}

.company-right-company {
  font-size: 50px;
  font-family: var(--font-primary);
  color: var(--color-primary);
}

.company-right p {
  margin: 40px 0px;
  font-size: 19px;
  font-weight: 300;
  line-height: 35px;

}

/************* End OUR COMPAY CSS *************/
/************* Start BY CATEGORY CSS *************/
.category-bg {
  background-image: url(./images/category.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 65px 65px;
}

.category-title {
  padding: 70px;
  text-align: center;
  color: var(--color-white) !important;
}

.category-title .company-right-company {
  color: var(--color-white) !important;
}

.category-title h4 {
  color: var(--color-white) !important;
}

.category-cards {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 140px;
}

.category-card.card {
  width: 16rem;
  background: transparent;
  border: 0px;
  text-align: center;
  transition: all .300s ease-in-out;
}

.category-card.card:hover {
  transform: translate(0px, -10px);
}

.category-card-img {
  width: 200px !important;
  height: 200px !important;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 100px !important;
  border: 1.5px dashed var(--color-white);
  padding: 25px;
  display: flex;
  margin: 0 auto;
}

.category-card-img img {
  object-fit: contain;
}
/* .category-card-img img:hover {
  transform: translateX(1);
} */

.category-card-body {
  color: var(--color-white) !important;
}

.category-card-title {
  padding-top: 15px;
  font-family: var(--font-tertiary);
  font-weight: 600 !important;
}

p.category-card-sub-title {
  font-size: 12px;
  font-family: var(--font-tertiary);
  font-weight: 100;
  margin: 0px;
}

.category-card-btn {
  background: transparent;
}

.category-card-btn a {
  color: var(--color-white) !important;
  font-size: 20px;
}

/************* End BY CATEGORY CSS *************/
/************* Start Selling Products CSS *************/
.selling-title {
  padding-top: 55px;
  display: flex;
  gap: 40px;
}

.selling-title img {
  width: 150px;
}

.selling-right {
  position: relative;
}

.selling-right-text {
  width: 185px;
  position: absolute;
  top: 200px;
  left: 60px;
}
.selling-right-text p{
  font-weight: 300; 
}

.selling-cards {
  display: flex;
  justify-content: space-between;
  padding-bottom: 35px;
}

.selling-card {
  width: 18rem;
  background: transparent;
  border: 0px !important;
}

.selling-card.margin_top {
  margin-top: 200px;
}

.selling-card.margin_top1 {
  margin-top: 100px;
}

.selling-card.margin_top2 {
  margin-top: 50px;
}

.selling-rating {
  color: #FF9900;
}

.selling-rating span {
  color: #BEBEBE;
}

.selling-card-title {
  font-family: var(--font-tertiary);
  font-weight: 800;
}

.selling-rating svg {
  font-size: 18px;
  margin-right: 4px;
}

.selling-card-text {
  font-family: var(--font-tertiary);
  font-size: 12px;
  margin: 0px;
}

a.selling-card-img {
  border: 1px solid rgba(208, 16, 24, 0.14) !important;
  border-radius: 20px !important;
  text-align: center;
  height: 400px;
  display: grid;
  align-items: center;
  justify-items: center;
}

a.selling-card-img img {
  width: 14rem;
  filter: drop-shadow(1px 1px 9px rgba(0, 0, 0, 0.5));
}

.selling-card-footer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.selling-card-arrow svg {
transition: all .500s ease-in-out;
}

.selling-card-arrow svg:hover {
  transform: translate(10px, 0px);
}

.selling-card-footer h2 {
  color: var(--color-primary);
  font-family: var(--font-four);
  margin: 0px;
}

.selling-card-footer button {
  background: transparent;
}

.selling-card-footer a {
  color: var(--color-primary);
  font-size: 28px;
}

/************* End Selling Products CSS *************/
/************* Start Brands component CSS *************/
.brands-bg {
  background-image: url(./images/brands.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.brands-logo {
  display: flex;
  justify-content: space-between;
  padding-bottom: 65px;
}

.brands-logo span {
  text-align: center;
  padding: 35px 0px;
  width: 15%;
  aspect-ratio: 3/2;
  background: linear-gradient(180deg, rgba(208, 16, 24, 0.700717787114846) 0%, rgba(208, 16, 24, 0) 100%);
  display: block;
}

.brands-logo img {
  transition: all 0.4s cubic-bezier(0.4, 0, 1, 1);
  width: 150px;
  object-fit: contain;
}

.brands-logo img:hover {
  transform: scale(1.1);
}

/************* End Brands component CSS *************/
/************* Start Custom Uniforms CSS *************/
.CustomUniforms-bg {
  background-image: url(./images/custom-bg.png);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0px;
  border-radius: 0px 0px 65px 65px;
}

.CustomUniforms-main.row {
  align-items: center;
}

.CustomUniforms-title h4.company-right-about {
  color: var(--color-white);
}

.CustomUniforms-title h1.company-right-our {
  color: var(--color-white);
}

.CustomUniforms-content p {
  color: var(--color-white);
  font-size: 15px;
  font-weight: 200;
  margin: 10px 0px 18px 0px;
}

/* .white-btn {
  padding: 8px 18px;
  border-radius: 27px;
}

.white-btn a {
  color: var(--color-primary);
  font-family: var(--font-tertiary);
  font-weight: 700;
} */

.CustomUniforms-img {
  width: 100%;
}

/************* End Custom Uniforms CSS *************/
/************* Start Testimonial CSS *************/
.testimonial-bg {
  background-image: url(./images/testimonial-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial-cards {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  padding: 140px 0px 110px 0px;
}

.testimonial-card {
  text-align: center;
  width: 26rem;
  border-radius: 0px !important;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid var(--color-primary) !important;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
  transition: all .400s ease-in-out;
}

.testimonial-card:hover {
  transform: translate(0px, -10px);
  color: var(--color-white);
  background-color: var(--color-primary) !important;
}
.testimonial-card:hover .testimonial-name h5 {
  color: var(--color-white);
}
.testimonial-card:hover .testimonial-name h5::before {
  background: var(--color-white);
}

.testimonial-img {
  width: 100%;
}

.testimonial-img img {
  border-radius: 100px;
  width: 125px;
  margin-top: -80px;
  margin-bottom: 28px;
}

p.testimonial-card-text {
  font-weight: 500;
  font-family: var(--font-fith);
  font-size: 18px;
  margin: 12px;
  line-height: 24px;
}

.testimonial-name {
  position: relative;
}

.testimonial-name h5 {
  font-family: var(--font-fith);
  color: var(--color-black);
}

.testimonial-name h5::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background: var(--color-black);
  top: 9px;
  left: 110px;
  border-radius: 50px;
}

.testimonial-right {
  margin: auto 0px !important;
}

a.view-all-btn img {
  width: 90px;
  margin-right: 25px;
  transition: all .500s ease-in-out;
}

a.view-all-btn img:hover {
  transform: translate(10px, 0px);
}

a.view-all-btn span {
  color: var(--color-black);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 22px;
}

/************* End Testimonial CSS *************/
/******** Start footer ********/
.footer_section {
  padding: 22px 0px;
  background-color: var(--color-primary);
}

.footer_section .news h2 {
  font-weight: 700;
  color: var(--color-white);
  margin: 0px;
  font-size: 35px;
  text-transform: uppercase;
}

.footer_section .news p {
  color: var(--color-white);
}

.footer_section2 {
  background-image: url(images/footer-Img.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 80px 0px 30px 0px;
  box-shadow: 0px 9px 12px 7px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 9999;
}


.footer_btn {
  justify-content: flex-end;
}

.footer_logo_section p {
  color: var(--color-white);
  margin: 30px 0px;
  font-size: 12px;
}

.footer_icon {
  display: flex;
}

.footer_icon ul {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 18px;
}

.footer_icon ul li a {
  color: var(--color-white);
  font-size: 30px;
}

.footer_icon ul li svg {
  width: 30px;
  transform: scale(1);
  object-fit: cover;
  transition: all 0.4s ease-in-out;
}

.footer_icon ul li svg:hover {
  width: 30px;
  transform: scale(1.34);
}

.footer-links h5 {
  color: var(--color-white);
  padding: 0px 0px 20px 0px;
  font-size: 19px;
  font-family: var(--font-primary);
}

.footer-links ul li a {
  color: var(--color-white);
  font-weight: 300;
  padding: 0px 0px 12px 0px;
  position: relative;
  display: inline-block;
  font-size: 13px;
}

.footer-links ul li a::before {
  position: absolute;
  content: "";
  background: var(--color-white);
  width: 0%;
  height: 1px;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

.footer-links ul li a:hover:before {
  color: var(--color-white);
  width: 100%;
}

.footer_section3 {
  padding: 20px 0px;
  background: var(--color-primary);
  text-align: center;
}

.footer_card {
  text-align: right;
}

.footer-links ul {
  padding: 0px;
}

p.footer_section3_p {
  color: var(--color-white);
  margin: 0px;
}

.footer-form-input {
  position: relative;
}

.footer-form-input input {
  border-radius: 80px;
  border: 3px solid var(--color-white);
  padding: 12px 20px;
  width: 100%;
  outline: none;
}

.footer-form-input button {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 80px;
  background: var(--color-primary);
  padding: 10px 30px;
  color: #fff;
}

.footer-form-input input:focus {
  color: var(--color-black);
}

/******** End footer ********/
/******** Section Header ********/
.header {
  /* margin-top: 5rem; */
  height: 20rem;
  overflow: hidden;
  border-bottom: 2px solid var(--color-gray-400);
}

.header__container {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  place-items: center;
}

.header__container-bg {
  position: absolute;
  width: 100%;
}

.header__container-bg img {
  width: 100%;
}

.header__content {
  position: relative;
  width: 44%;
  margin: 0 auto;
  text-align: center;
  color: var(--color-white);
}

.header__content h1 {
  margin-bottom: 0.7rem;
  font-weight: 700;
  font-size: 55px;
}

.header_link a {
  color: rgba(255, 255, 255, 0.75);
  margin: 10px;
}

a.header_link_a {
  color: var(--color-primary);
}

/******** Section Header ********/
/******** Shop page ********/
.shop-bg {
  /* background-image: url(./images/shop-bg.jpg); */
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.category {
  margin-top: 27px;
}

.category_btn {
  outline: none;
  background: transparent;

}


h5.categories {
  background: var(--color-secondary);
  padding: 15px;
  margin: 0px;
  color: var(--color-white);
  font-size: 22px;
  font-weight: 600;
}

.category-name-color button.accordion-button.collapsed {
  background: var(--background-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shop_category .accordion-button::after {
  background-image: url(./images/arrow-img-removebg-preview.png) !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
}

.shop_category .accordion-button:not(.collapsed)::after {
  filter: brightness(3);
}

h5.price-range {
  background: var(--color-secondary);
  padding: 15px;
  margin: 0px;
  color: var(--color-white);
  font-size: 22px;
  font-weight: 600;
}

ul.price__range {
  margin: 0px;
  padding: 0px;
  background: var(--color-white);
  color: #000;
}

ul.price__range li {
  padding: 15px 20px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  gap: 40px;
}

.price-span {
  display: flex;
  gap: 20px;
  font-size: 13px;
}

.sort-by {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
  margin-left: 17px;
  align-items: center;
}

.sort-by-form-group {
  display: flex;
  gap: 20px;
}

form.sort-by-form select#rating-filter {
  width: 300px;
  padding: 13px 15px;
  border: 1px solid #dee2e6;
  color: var(--color-secondary);
}

form.sort-by-form-2 select {
  width: 150px;
  padding: 13px 15px;
  border: 1px solid #dee2e6;
  color: var(--color-secondary);
}

.page-show-span {
  display: flex;
  gap: 12px;
  font-size: 14px;
}

/******** Shop Page Css********/
/******** Faqs Page Css ********/
.faq-bg {
  /* background-image: url(./images/shop-bg.jpg); */
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.faq_section {
  padding: 90px 0px;
}

.faq-accordion {
  width: 85%;
  margin: 0px auto;
}

.faq_section_heading {
  padding: 60px 0px;
  font-size: 48px;
  font-weight: 700;
  color: var(--color-gray-400);
  text-align: center;
}

.faq_section_heading span {
  color: var(--color-primary);
}

.faq-accordion button.accordion-button.collapsed {
  background: var(--color-black) !important;
  color: var(--color-white);
}

.faq-accordion button.accordion-button::after {
  color: var(--color-white);
}

.faq-accordion-body.accordion-body {
  font-size: 14px;
  background: #000;
  color: var(--color-secondary);
  border-left: 3px solid var(--color-primary);
}

.faq-accordion-header button.accordion-button {
  background: var(--background-gradient2) !important;
  color: #fff;
}

.faq-Accordion-item.accordion-item {
  margin-bottom: 30px;
}

h2.faq-accordion-header.accordion-header {
  border-left: 3px solid var(--color-primary);
}

.faq-accordion .faq-accordion-header .accordion-button::after {
  background-image: url(./images/arrow-img-removebg-preview.png) !important;
}

.faq-accordion .faq-accordion-header .accordion-button:not(.collapsed)::after {
  filter: brightness(3);
}

/******** Faqs Page Css ********/
/******** Start Single Product Page Css ********/

.product-bg {
  /* background-image: url(./images/shop-bg.jpg); */
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;

}

.product-detail {
  padding: 90px 0px;
  width: 90%;
  margin: 0px auto;
}

.product-detail-slider {
  width: 80% !important;
}

.product-detail-slider img {
  width: 100%;
}

.product-detail-slider-sec {
  width: 40% !important;
}

.product-detail-slider-sec img {
  width: 100%;
}

.product-detail .slick-slide {
  text-align: center;
}

.product_detail_info_Heading {
  display: flex;
  justify-content: space-between;
}

.product_detail_info_Heading h3 {
  font-weight: 700;
}

.product_detail_info_Heading h2 {
  font-weight: 700;
  background: var(--background-gradient2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p.product_detail_info_des {
  font-size: 14px;
  color: var(--color-secondary);
}

.product_detail_info_quantity {
  display: flex;
  gap: 50px;
  align-items: flex-start;
}

.product_detail_info_quantity p {
  font-size: 18px;
  font-weight: 600;
}

.quantity_btns button {
  background: transparent;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  line-height: 1px;
}

.quantity_btns button:hover {
  background: var(--background-gradient2);
  color: #fff;

}

.quantity_btns input {
  width: 100px;
  background: #fff;
  border: 1px solid #c0c0c0;
  text-align: center;
}

.quantity_btns {
  display: flex;
  gap: 14px;
  align-items: center;
}

.product_detail_info_delivery a {
  color: var(--color-secondary);
}

.product_detail_info_delivery a span svg {
  color: var(--color-black);
  transform: rotateY(180deg);
  font-size: 20px;
}

p.product_detail_info_delivery_link {
  display: flex;
  gap: 20px;
  font-size: 14px;
}

.product_detail_info_des_tab.nav.nav-pills {
  gap: 50px;
  padding: 60px 0px;
}

.main_btn2 .nav-link {
  padding: 0px;
}

.nav-pills .main_btn2 .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--color-white);
  border: 1px solid transparent;
  background: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 0%, rgba(226, 128, 198, 1) 100%);
  border-image: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 1) 0%, rgba(226, 128, 198, 1) 100%);
  border-image-slice: 1;
  padding: 8px 25px;
  transition: all 0.5s;
}

.nav-pills .main_btn2 .nav-link {
  color: var(--color-secondary);
  background: -webkit-linear-gradient(90deg, rgba(140, 114, 215, 0.25) 0%, rgba(226, 128, 198, 0.25) 100%);
  padding: 8px 25px;
  border-radius: 0px;
  font-weight: 600;
}

/******** Review Page Css ********/
.review_main h3 {
  font-weight: 600;
}

.single_product_review .product_rating {
  display: flex;
  flex-direction: column;
}

span.red_star {
  color: #ff2c18;
  display: inline-block;
}

.single_product_review .product_rating label {
  font-size: 18px;
  color: var(--color-black);
  margin: 8px 0px;
}

.product_rating svg {
  width: 25px;
}

.single_product_review .product_rating textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #ddd;
  resize: none;
  border-radius: 0;
  color: #333;
  padding: 10px 15px;
}

/******** End Single Product Page Css ********/
/******** Page Not Found Page Css ********/
.page-not-found {
  padding: 9rem 0;
  text-align: center;
}

.page-not-found h2 {
  font-size: 10rem;
  font-weight: 700;
  background: var(--background-gradient2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-not-found h3 {
  font-size: 4.2rem;
  font-weight: 600;
  color: var(--color-black);
}

.page-not-found p {
  color: var(--color-black);
  margin: 2rem 0;
}

/******** Page Not Found Page Css ********/

/* custom_btn_card Button */
.custom_btn_card {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50px;
}

.custom_btn_card a {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 30px;
  transition: 0.3s;
}

.custom_btn_card::after {
  position: absolute;
  content: "";
}

.custom_btn_card::before {
  position: absolute;
  content: "";
  background: var(--color-primary);
  transition: 0.3s ease-out;
  border-radius: 50px;
  box-shadow: inset 0px 3px 7px rgba(255, 255, 255, 1);
}

.custom_btn_card a {
  color: var(--color-white);
  border: 1px solid var(--color-primary);
  transition: 0.2s 0.1s;
  border-radius: 50px;
  font-family: var(--font-tertiary);
  font-weight: 500;
  z-index: 99;
}

.custom_btn_card a:hover {
  color: var(--color-primary);
  transition: 0.2s 0.1s;
}

.custom_btn_card::before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.custom_btn_card:hover::before {
  height: 0%;
}

/* custom_btn_card Button */
/* custom_btn-white Button */
.custom_btn-white {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50px;
}

.custom_btn-white a {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 30px;
  transition: 0.3s;
}

.custom_btn-white::after {
  position: absolute;
  content: "";
}

.custom_btn-white::before {
  position: absolute;
  content: "";
  background: var(--color-white);
  transition: 0.3s ease-out;
  border-radius: 50px;
}

.custom_btn-white a {
  color: var(--color-primary);
  border: 1px solid var(--color-white);
  transition: 0.2s 0.1s;
  border-radius: 50px;
  font-family: var(--font-tertiary);
  font-weight: 600;
  z-index: 99;
}

.custom_btn-white a:hover {
  color: var(--color-white);
  transition: 0.2s 0.1s;
}

.custom_btn-white::before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.custom_btn-white:hover::before {
  height: 0%;
}
/* custom_btn_card Button */
/* Loader css */
.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 3px solid var(--color-primary);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader2 {
  border: 3px solid var(--color-primary2);
  width: 220px;
  height: 220px;
  position: relative;
  top: -210px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader3 {
  border: 3px solid var(--color-primary);
  width: 240px;
  height: 240px;
  position: relative;
  top: -441px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader4 {
  border: 3px solid var(--color-primary2);
  width: 260px;
  height: 260px;
  position: relative;
  top: -692px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

@keyframes rotate {
  0% {
    transform: rotateZ(-360deg)
  }

  100% {
    transform: rotateZ(0deg)
  }
}

@keyframes rotate2 {
  0% {
    transform: rotateZ(360deg)
  }

  100% {
    transform: rotateZ(0deg)
  }
}

#text {
  color: rgb(14, 13, 13);
  font-family: Arial;
  font-size: 20px;
  position: relative;
  top: -840px;
}

/* Loader css */